import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import styled from 'styled-components';

import {
  EditIcon,
  SortDownIcon,
  LastPageIcon,
  FirstPageIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,
  ViewIcon,
  ChevronDownIcon,
} from '../../components/atoms/Icons';

import { Job, JobPost } from '../../generated/types';
import { ClearIcon } from '../../components/atoms/Icons/ClearIcon';
import { ErrorBoundary } from '../../components/molecules/ErrorBoundary';
import { Colors } from '../../styles/colors';
import {
  JobPostTableStatus,
  JobTableCompany,
  JobTablePosition,
  JobTableRate,
} from '../../components/molecules/JobTable/JobTable';

export const JobsOffersViewTable: React.FC<JobsViewTableProps> = ({
  handleViewClick,
  handleEditClick,
  jobPosts,
}: JobsViewTableProps) => {
  // https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
  // Bug so we need to clone the data
  const clonedData = jobPosts.map((jobPost) => ({ ...jobPost }));

  return (
    <ErrorBoundary>
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: 'Company',
            field: 'company',
            filtering: true,
            searchable: true,
            customFilterAndSearch: (term, rowData) => {
              const company = rowData?.company?.toLowerCase() || '';
              return company.includes(term);
            },
            render: (jobPost) => {
              return <JobTableCompany company={jobPost.company} />;
            },
          },
          {
            title: 'Position',
            field: 'jobTitle',
            filtering: true,
            searchable: true,
            customFilterAndSearch: (term, rowData) => {
              const jobTitle = rowData?.jobTitle?.toLowerCase() || '';
              return jobTitle.includes(term);
            },
            render: (job) => {
              return (
                <JobTablePosition
                  onClick={() => {
                    handleViewClick(job?.uuid);
                  }}
                  jobTitle={job.jobTitle}
                  jobTitleUrl={job.jobUrl}
                />
              );
            },
          },
          {
            title: 'Rate',
            field: 'rate',
            filtering: false,
            searchable: false,
            render: (job) => <JobTableRate ir35={job.ir35} rate={job.rate} duration={job.duration}></JobTableRate>,
          },
          {
            title: 'Status',
            field: 'rate',
            filtering: false,
            searchable: false,
            render: (job) => <JobPostTableStatus status={job.status}></JobPostTableStatus>,
          },
        ]}
        data={clonedData}
        title="Your Job Offers Posts"
        actions={[
          (rowData) => ({
            icon: () => <ViewIcon />,
            tooltip: 'View Job Post',
            onClick: (event, rowData) => {
              event.preventDefault();
              // @ts-ignore-next-line
              handleViewClick((rowData as JobPost)?.uuid);
            },
          }),
          (rowData) => ({
            icon: () => <EditIcon size={1.2} />,
            tooltip: 'Edit Job Post',
            onClick: (event, rowData) => {
              event.preventDefault();
              // @ts-ignore-next-line
              handleEditClick((rowData as Job)?.uuid);
            },
            hidden: !rowData?.permissions.includes('EDIT_JOBPOST'),
          }),
        ]}
        options={{
          sorting: false,
          filtering: false,
          search: false,
        }}
      />
    </ErrorBoundary>
  );
};

const tableIcons: any = {
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <SortDownIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <ChevronDownIcon {...props} ref={ref} />),
};

interface JobsViewTableProps {
  jobPosts: JobPost[];
  handleViewClick: (jobUuid: string) => void;
  handleEditClick?: (jobUuid: string) => void;
  handleDeleteClick?: (jobUuid: string) => void;
  handleAddContactClick?: (jobUuid: string) => void;
}

export default JobsOffersViewTable;
